import store from '../store'
import router from '../router'

const checkLink = (to, from, next) => {
    if (store.getters.getUsers === undefined) {
        store.commit('setUsers', window.getUsers())
    }
    const Users = store.getters.getUsers

    const aktuellerMitarbeiter = Users.filter(v => v.MitarbeiterLink === to.params.MitarbeiterID)
    const Frage = to.params.Frage
    if (aktuellerMitarbeiter.length > 0) {
        store.commit("setCurrentUser", aktuellerMitarbeiter[0])
        if (to.name === "Fotos" || to.name === "Abschluss") {
            next()
        }
        else if (Frage === undefined) {
            router.replace({ path: '/' + to.params.MitarbeiterID + "/Fragen/1" })
        }
        else {
            next()
        }
    }
    else {
        router.replace({ path: '/' })
    }
}

export default checkLink
