import Vue from 'vue'
import VueRouter from 'vue-router'
//import Fragebogen from '../views/Fragebogen.vue'
import checkLink from './middleware/checkLink'

Vue.use(VueRouter)

const loadView = (view) => {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: "KeineID",
    component: loadView("KeineID")
  },
  {
    path: '/:MitarbeiterID',
    name: 'Fragebogen_a',
    beforeEnter: checkLink,
    component: loadView("Fragebogen")
  },
  {
    path: '/:MitarbeiterID/Fotos',
    name: 'Fotos',
    beforeEnter: checkLink,
    component: loadView("Fotos")
  },
  {
    path: '/:MitarbeiterID/Abschluss',
    name: 'Abschluss',
    beforeEnter: checkLink,
    component: loadView("Abschluss")
  },
  {
    path: '/:MitarbeiterID/Fragen/:Frage',
    name: 'Fragebogen',
    beforeEnter: checkLink,
    component: loadView("Fragebogen"),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: loadView("Impressum")
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: loadView("Datenschutz")
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
