import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/emailjs'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesome from 'vue-awesome/components/Icon'
import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/check-circle'

Vue.component('v-icon', VueAwesome)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
