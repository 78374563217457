<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <vue-page-transition name="fade-in-down">
      <router-view :key="rvKey" />
    </vue-page-transition>
    <b-navbar fixed="bottom" variant="light">
      <b-navbar-nav class="ml-auto">
        <b-nav-item :to="{ name: 'Impressum' }">Impressum</b-nav-item>
        <b-nav-item :to="{ name: 'Datenschutz' }">Datenschutz</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <footer>
      <cookie-law theme="base" buttonText="OK">
        <div slot="message">
          Auf dieser Webseite werden ausschließlich für den Betrieb notwendige Cookies verwendet.
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  computed: {
    rvKey() {
      return this.$store.getters.getRvKey;
    },
  },
  components: { CookieLaw },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+HK");
@import url("https://fonts.googleapis.com/css?family=Lobster");
body {
  background: #ee9ca7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffdde1,
    #ee9ca7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffdde1,
    #ee9ca7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family: "Noto Sans HK";
}
h3 {
  font-size:1.5rem;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.spinnerSize {
  width: 1rem;
  height: 1rem;
}
.h3Cl {
  font-size: 1.75rem;
}
.headerFont {
  font-family: "Lobster";
  font-size: 2.3rem;
  margin-bottom:10px;

}
</style>
