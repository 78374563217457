import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Users: undefined,
    currentUser: {},
    rvKey: Math.random(),
    answers: new Array(22),
    bildLinks: "",
    bildRechts: "",
    bildVorderseite: "",
    transition: ""
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload
    },
    setRvKey(state, payload) {
      state.rvKey = payload
    },
    setAnwser(state, payload) {
      state.answers[--payload.Frage] = { Fragentext: payload.Fragentext, Antwort: payload.Antwort, Antwort_2: payload.Antwort_2, Antwort_3: payload.Antwort_3 }
    },
    setBildLinks(state,payload) {
      state.bildLinks = payload
    },
    setBildRechts(state,payload) {
      state.bildRechts = payload
    },
    setBildVorderseite(state,payload) {
      state.bildVorderseite = payload
    },
    setTransition(state,payload) {
      state.transition = payload
    },
    setUsers(state,payload) {
      state.Users = payload
    }
  },
  actions: {
    setTransition: (context, payload) => {
      return new Promise((resolve) => {
        context.commit('setTransition',payload)
        resolve()
      })
    },
  },
  modules: {
  },
  getters: {
    getUsers: state => {
      return state.Users
    },
    getCurrentUser: state => {
      return state.currentUser
    },
    getAnwsers: state => {
      return state.answers
    },
    getRvKey: state => {
      return state.rvKey
    },
    getBildLinks: state => {
      return state.bildLinks
    },
    getBildRechts: state => {
      return state.bildRechts
    },
    getBildVorderseite: state => {
      return state.bildVorderseite
    },
    getTransition: state => {
      return state.transition
    }
  }
})
